<template>
  <v-row>
    <v-col cols="7">
      <v-card>
        <v-card-title>
          Filtre
        </v-card-title>
        <v-card-text>
          <div v-if="filters.length > 0">
            <v-data-table
              :headers="headers"
              :hide-default-footer="true"
              :items="filters"
            >
              <template v-slot:body="props">
                <draggable v-model="filters" tag="tbody">
                  <tr v-for="(item, index) in filters" :key="index" class="custom-tr children cursor-move">
                    <td style="width: 65px">
                      <vs-icon icon="fa-bars" icon-pack="fal"></vs-icon>
                    </td>
                    <td>{{ item.type }}</td>
                    <td>{{ item.name }}</td>
                    <td class="text-right" style="width: 100px">
                      <div v-show="item.deletable">
                        <delete-button without-confirmation @delete="deleteItem(index)"></delete-button>
                      </div>
                      <div v-show="!item.deletable">
                        <help-icon
                          text="Filtrul nu poate fi șters. Acesta este utilizat la afișarea 3rd lvl din meniu a unei categorii."/>
                      </div>
                    </td>
                  </tr>
                </draggable>
              </template>
            </v-data-table>
          </div>
          <div v-else> Nu este nici un filtru atribuit</div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" class="primary-button" @click="saveFilters">Salveaza</v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col cols="5">
      <v-card>
        <v-card-title>
          Adauga filtru
        </v-card-title>
        <v-card-text>
          <category-filters-component-type-select :category-id="currentCategoryId"/>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import draggable from 'vuedraggable'
import DeleteButton from '@/components/general-form/DeleteButton'
import CategoryFiltersComponentTypeSelect
  from '@/views/ProductManagement/Categories/components/CategoryFiltersComponentTypeSelect'
import HelpIcon from '@/components/HelpIcon'

export default {
  name: 'CategoryFilters',
  components: {
    HelpIcon,
    CategoryFiltersComponentTypeSelect,
    DeleteButton,
    draggable
  },
  data () {
    return {
      headers: [
        {
          text: '',
          value: 'handler',
          width: '65',
          sortable: false
        },
        {
          text: 'Tip',
          value: 'type',
          sortable: false
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          width: '100',
          sortable: false,
          align: 'right'
        }
      ]
    }
  },
  computed: {
    currentCategoryId () {
      return this.$route.params.id
    },
    filters: {
      get () {
        return this.$store.getters['CategoryFilters/filters']
      },
      set (value) {
        this.$store.commit('CategoryFilters/SET_FILTERS', value)
      }
    }
  },
  methods: {
    saveFilters () {
      this.$store.dispatch('startLoading')
      this.$store.dispatch('CategoryFilters/saveItems')
        .then(() => {
          this.$vs.notify({
            title: 'Succes!',
            text: 'Filtrele au fost salvate!',
            color: 'success'
          })
        })
        .catch(() => {
          this.$vs.notify({
            title: 'Eroare',
            text: 'A aparut o eroare la stergere. Va rugam reincarcati pagina sau incercati mai tarziu',
            color: 'danger'
          })
        })
        .finally(() => {
          this.$store.dispatch('stopLoading')
        })
    },
    deleteItem (index) {
      this.$store.commit('CategoryFilters/DELETE_ITEM', index)
    }
  },
  mounted () {
    this.$store.commit('CategoryFilters/SET_CATEGORY_ID', this.currentCategoryId)
    this.$store.dispatch('CategoryFilters/loadItems')

  }
}
</script>

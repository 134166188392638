<template>
  <div>
    <v-select
      v-model="selectedAttributeId"
      :items="filterType.options"
      item-text="label"
      item-value="machine_name"
      label="Alege"
    ></v-select>
    <v-btn
      :disabled="!selectedAttributeId"
      elevation="2"
      @click="addFilter"
    >Adauga
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'BaseProductFilter',
  props: {
    filterType: {
      required: true
    },
    storeName: ''
  },
  data () {
    return {
      selectedAttributeId: false
    }
  },
  computed: {
    selectedAttribute () {
      return this.filterType.options.find(element => element.machine_name === this.selectedAttributeId)
    }
  },
  methods: {
    addFilter () {
      this.$store.dispatch(this.storeName, {
        type: 'baseProducts',
        model_id: this.selectedAttribute.machine_name,
        name: this.selectedAttribute.label,
        deletable: true
      })
      this.selectedAttributeId = false
    }
  }

}
</script>

<template>
  <v-expansion-panels>
    <v-expansion-panel v-for="(filterType, index) in filterTypes" :key="index">
      <v-expansion-panel-header>
        {{ filterType.label }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <component :is="`${filterType.type}-filter`" :filter-type="filterType" :store-name="'CategoryFilters/addNewFilter'"></component>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>

import ComponentFilter from '@/views/ProductManagement/Categories/components/Filters/ComponentFilter'
import AttributeFilter from '@/views/ProductManagement/Categories/components/Filters/AttributeFilter'
import PriceFilter from '@/views/ProductManagement/Categories/components/Filters/PriceFilter'
import BaseProductFilter from '@/views/ProductManagement/Categories/components/Filters/BaseProductFilter'
import BpAttributeFilter from "@/views/ProductManagement/Categories/components/Filters/BpAttributeFilter";

export default {
  name: 'CategoryFiltersComponentTypeSelect',
  components: {
    BpAttributeFilter,
    BaseProductFilter,
    PriceFilter,
    AttributeFilter,
    ComponentFilter
  },
  data () {
    return {
      filterTypes: []
    }
  },
  props: {
    categoryId: {
      required: true
    }
  },
  mounted () {
    this.$http.get(`product-categories/${this.categoryId}/filters/types`)
      .then(({data}) => {
        this.filterTypes = data
      })
  }
}
</script>
